import sportIds from '@/services/helpers/sports';

const {
  BASKETBALL_ID,
  FOOTBALL_ID,
  HOCKEY_ID,
  BASEBALL_ID,
} = sportIds;

export const generateEventColumns = () => ([
  {
    id: 'startsAt',
    label: 'Time',
    type: 'label-icon',
    position: 0,
  },
  {
    id: 'eventName',
    label: 'Event',
    type: 'label-text',
    position: 1,
  },
  {
    id: 'RESULT',
    label: 'Moneyline',
    type: 'market',
    position: 2,
  },
  {
    id: 'HANDICAP',
    label: 'Spread',
    type: 'market',
    position: 3,
  },
  {
    id: 'OVER_UNDER',
    label: 'Total',
    type: 'market',
    position: 4,
  },
]);

export const generatePlayerColumns = () => ({
  [FOOTBALL_ID]: [
    {
      id: 'startsAt',
      label: 'Time',
      type: 'label-icon',
      position: 0,
    },
    {
      id: 'playerName',
      label: 'Player',
      type: 'label-text',
      position: 1,
    },
    {
      id: 'PLAYER_PASSING_ATTEMPTS_OVER_UNDER',
      label: 'Passing attempts',
      type: 'market',
      position: 2,
    },
    {
      id: 'PLAYER_PASSING_COMPLETIONS_OVER_UNDER',
      label: 'Passing completions',
      type: 'market',
      position: 3,
    },
    {
      id: 'PLAYER_PASSING_TOUCHDOWNS_OVER_UNDER',
      label: 'Passing TD',
      type: 'market',
      position: 4,
    },
    {
      id: 'PLAYER_PASSING_YARDS_OVER_UNDER',
      label: 'Passing yards',
      type: 'market',
      position: 5,
    },
    {
      id: 'PLAYER_RECEIVING_YARDS_OVER_UNDER',
      label: 'Receiving yards',
      type: 'market',
      position: 6,
    },
    {
      id: 'PLAYER_RECEPTIONS_OVER_UNDER',
      label: 'Receptions',
      type: 'market',
      position: 7,
    },
    {
      id: 'PLAYER_RUSHING_AND_PASSING_YARDS_OVER_UNDER',
      label: 'Rushing and passing yards',
      type: 'market',
      position: 8,
    },
    {
      id: 'PLAYER_RUSHING_YARDS_OVER_UNDER',
      label: 'Rushing yards',
      type: 'market',
      position: 9,
    },
    {
      id: 'PLAYER_TOUCHDOWN_X_OR_MORE',
      label: 'X or more TD',
      type: 'market',
      position: 10,
    },
  ],
  [BASKETBALL_ID]: [
    {
      id: 'startsAt',
      label: 'Time',
      type: 'label-icon',
      position: 0,
    },
    {
      id: 'playerName',
      label: 'Player',
      type: 'label-text',
      position: 1,
    },
    {
      id: 'PLAYER_POINT_OVER_UNDER',
      label: 'Points',
      type: 'market',
      position: 2,
    },
    {
      id: 'PLAYER_REBOUND_OVER_UNDER',
      label: 'Rebounds',
      type: 'market',
      position: 3,
    },
    {
      id: 'PLAYER_ASSIST_OVER_UNDER',
      label: 'Assists',
      type: 'market',
      position: 4,
    },
    {
      id: 'PLAYER_THREE_POINTER_OVER_UNDER',
      label: 'Three pointers',
      type: 'market',
      position: 5,
    },
  ],
  [HOCKEY_ID]: [
    {
      id: 'startsAt',
      label: 'Time',
      type: 'label-icon',
      position: 0,
    },
    {
      id: 'playerName',
      label: 'Player',
      type: 'label-text',
      position: 1,
    },
    {
      id: 'PLAYER_POINT_OVER_UNDER',
      label: 'Points',
      type: 'market',
      position: 2,
    },
    {
      id: 'PLAYER_GOAL_OVER_UNDER',
      label: 'Goals',
      type: 'market',
      position: 3,
    },
    {
      id: 'PLAYER_ASSIST_OVER_UNDER',
      label: 'Assists',
      type: 'market',
      position: 4,
    },
    {
      id: 'PLAYER_FIRST_GOALSCORER',
      label: 'First goalscorer',
      type: 'market',
      position: 5,
    },
    {
      id: 'PLAYER_LAST_GOALSCORER',
      label: 'Last goalscorer',
      type: 'market',
      position: 6,
    },
    {
      id: 'PLAYER_ANYTIME_GOALSCORER',
      label: 'Anytime goalscorer',
      type: 'market',
      position: 7,
    },
  ],
  [BASEBALL_ID]: [
    {
      id: 'startsAt',
      label: 'Time',
      type: 'label-icon',
      position: 0,
    },
    {
      id: 'playerName',
      label: 'Player',
      type: 'label-text',
      position: 1,
    },
    {
      id: 'PLAYER_BASES_OVER_UNDER',
      label: 'Bases',
      type: 'market',
      position: 2,
    },
    /* {
      id: 'PLAYER_HOME_RUN',
      label: 'Home runs',
      type: 'market',
      position: 3,
    }, */
    /* {
      id: 'PLAYER_ON_BASE',
      label: 'On base',
      type: 'market',
      position: 4,
    }, */
    /* {
      id: 'PLAYER_ON_BASE_TWO_PLUS',
      label: 'On Base Twice or More',
      type: 'market',
      position: 5,
    }, */
    {
      id: 'PLAYER_RUN_OVER_UNDER',
      label: 'Runs',
      type: 'market',
      position: 3,
    },
    {
      id: 'PLAYER_STRIKEOUT_OVER_UNDER',
      label: 'Strikeouts',
      type: 'market',
      position: 4,
    },
  ],
});
